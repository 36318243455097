const axios = require('axios')

const getUploadUrl = async () => {
  // return new Promise(async (resolve, reject) => {
  try {
    axios.defaults.baseURL = 'https://y9txgstlfc.execute-api.eu-west-1.amazonaws.com/am/altripan/ordastris/ontvangstgoederen'
    axios.defaults.headers.common['x-api-key'] = 'Rm8POqY1Nk8oid9fNJtX19M05QiAG6cJ2RbucZIR'

    const apiResponse = await axios.get('/uploadurl')
    console.log('apiResponse', apiResponse)
    const uploadObj = apiResponse.data
    return (uploadObj)
  } catch (error) {
    console.log('getUploadUrl error', error)
    throw new Error(error)
  }
  // })
}

const saveCsv = async (data) => {
  try {
    const apiResponse = await axios.post('/saveascsv', { data })
    console.log('api POST response', apiResponse)
    const downloadObj = apiResponse.data.downloadUrl
    return downloadObj
  } catch (error) {
    console.log('saveCsv error', error)
    throw new Error(error)
  }
}

const saveJson = async (data) => {
  try {
    axios.defaults.baseURL = 'https://y9txgstlfc.execute-api.eu-west-1.amazonaws.com/am/altripan/ordastris/ontvangstgoederen'
    axios.defaults.headers.common['x-api-key'] = 'Rm8POqY1Nk8oid9fNJtX19M05QiAG6cJ2RbucZIR'

    const apiResponse = await axios.post('/saveasjson', { data })
    console.log('api POST response', apiResponse)
    const downloadObj = apiResponse.data.downloadUrl
    return downloadObj
  } catch (error) {
    console.log('saveJson error', error)
    throw new Error(error)
  }
}

// const saveJsonToTestTris = async (data) => {
//   try {
//     axios.defaults.baseURL = 'https://y9txgstlfc.execute-api.eu-west-1.amazonaws.com/am/altripan/ordastris/ontvangstgoederen'
//     axios.defaults.headers.common['x-api-key'] = 'Rm8POqY1Nk8oid9fNJtX19M05QiAG6cJ2RbucZIR'

//     data.saveToTest = true
//     data.saveToLive = false

//     const apiResponse = await axios.post('/saveasjson', { data })
//     console.log('api POST response', apiResponse)
//     const downloadObj = apiResponse.data.downloadUrl
//     return downloadObj
//   } catch (error) {
//     console.log('saveJson error', error)
//     throw new Error(error)
//   }
// }

exports.getUploadUrl = getUploadUrl
exports.saveCsv = saveCsv
exports.saveJson = saveJson
// exports.saveJsonToTestTris = saveJsonToTestTris
