<template>
  <div id="main">
    <a-layout>
      <a-layout-content>
        <a-row style="text-align: center;"><a href="/">upload excel</a></a-row>
        <a-row>
          <a-col :span="10" :offset="7" class="h100">
            <a-input-search
              placeholder="input search text"
              size="large"
              @search="addRegistrationNumber"
            >
              <template #enterButton>
                <a-button>Add Registration Number</a-button>
              </template>
            </a-input-search>
            <div style="margin-top: 10px">
              <a-tag v-for="rn in registrationNumbers" :key="rn" closable @close="removeRegistrationNumber(rn)">{{ rn }}</a-tag>
              <a-button v-if="registrationNumbers.length" type="primary" size="small" @click="loadFromOrdas">Load from ORDAS</a-button>
            </div>
            <!-- <a-upload-dragger
              :action="uploadUrl"
              :customRequest="customRequest"
              name="file"
              :multiple="false"
              :show-upload-list="false"
              @change="handleChange"
              :style="{ display: displayUpload }"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">
                SELECT Click or drag file to this area to upload
              </p>
              <p class="ant-upload-hint"></p>
            </a-upload-dragger> -->
          </a-col>
        </a-row>
        <a-row style="text-align: center">&nbsp;v2.4</a-row>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
const { mapActions, mapState } = require('vuex')

export default {
  data: function () {
    return {
      displayUpload: 'none',
      registrationNumbers: [] // [24019163] // [24021462, 24021463]
    }
  },
  mounted: function () {
    this.getUploadUrl()
  },
  methods: {
    addRegistrationNumber: function (regNumbers) {
      regNumbers = regNumbers.split(',')
      regNumbers.forEach(regNumber => {
        regNumber = parseInt(regNumber)
        console.log('addRegistrationNumber', regNumber)
        if (!this.registrationNumbers.find(rn => regNumber === rn)) {
          console.log('new number')
          this.registrationNumbers.push(regNumber)
        } else {
          console.log('existing number')
        }
      })
    },
    removeRegistrationNumber: function (regNumber) {
      const index = this.registrationNumbers.indexOf(regNumber)
      console.log('removeRegistrationNumber', regNumber, index)
      this.registrationNumbers.splice(index, 1)
    },
    loadFromOrdas: function () {
      this.getOrdasData(this.registrationNumbers)
    },
    // eslint-disable-next-line dot-notation
    ...mapActions(['getOrdasData', 'getUploadUrl'])
  },
  computed: {
    ...mapState({
      // uploadUrl: state => state.uploadUrl,
      orders: state => state.orders
    })
  },
  watch: {
    // uploadUrl: function (val, oldVal) {
    //   this.displayUpload = 'inline'
    // },
    orders: function (val, oldVal) {
      this.$router.push('/tabpage')
    }
  }
}
</script>

<style scoped>
  div#main{
    height: 100%;
  }

  .ant-layout{
    height: 100%;
  }

  .ant-layout-content{
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .ant-row{
    height: 5%;
  }

  .ant-row:nth-child(2){
    flex-grow: 1;
  }

  .h100{
    height: 100%;
  }
</style>
