<template>
  <div id="mainOrder">
    <a-collapse v-model="activeKey">
      <a-collapse-panel key="1" header="General information" >
        <a-form :form="form" layout="vertical">
          <a-row>
            <a-col :span="5">
              <h3>General</h3>
              <a-form-item label="Group">
                <a-input
                  v-decorator="['group', {initialValue: 'Import'}]"
                  placeholder="Group"
                  disabled
                />
              </a-form-item>
              <a-form-item label="Departement">
                <a-select
                  v-decorator="['departement', { rules: [{ required: true }], initialValue: ordersGeneralInfo[order].departement }]"
                  placeholder="Departement"
                  show-search
                >
                  <a-select-option v-for="department in departments" :key="department" :value="department">{{ department }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="SLA">
                <a-select
                  ref="rsla"
                  v-decorator="['SLA', { rules: [{ required: true }], initialValue: ordersGeneralInfo[order].SLA }]"
                  placeholder="SLA"
                  @change="handleSLAChanges"
                  show-search
                >
                  <a-select-option v-for="sla in slas" :key="sla" :value="sla">{{ sla }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Terms of delivery">
                <a-input
                  v-decorator="['termsOfDelivery', {initialValue: ordersGeneralInfo[order].termsOfDelivery}]"
                  placeholder="Terms of delivery"
                />
              </a-form-item>
              <a-form-item label="Freight Prepaid">
                <a-switch
                  v-decorator="['freightPrepaid', { valuePropName: 'checked', initialValue: ordersGeneralInfo[order].freightPrepaid }]"
                  placeholder="Freight Prepaid"
                >
                  <a-icon slot="checkedChildren" type="check" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>
              </a-form-item>
              <a-form-item label="Country of Origin">
                <a-select
                  v-decorator="['countryOfOrigin', { initialValue: ordersGeneralInfo[order].countryOfOrigin }]"
                  placeholder="Country of Origin"
                  @search="handleCountrySearch"
                  @inputKeydown="inputCountryDown"
                  show-search
                  :autoClearSearchValue="false"
                >
                  <a-select-option v-for="countryOfOrigin in countriesOfOrigin" :key="countryOfOrigin" :value="countryOfOrigin">{{ countryOfOrigin }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Package code">
                <a-select
                  v-decorator="['packageCode', {initialValue: ordersGeneralInfo[order].packageCode || packagecodes[0]}]"
                  placeholder="Package Code"
                  show-search
                >
                  <a-select-option v-for="packagecode in packagecodes" :key="packagecode" :value="packagecode">{{ packagecode }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="PI Status">
                 <a-input
                  v-decorator="['piStatus', {initialValue: ordersGeneralInfo[order].piStatus}]"
                  placeholder="PI Status"
                />
              </a-form-item>
            </a-col>
            <a-col :span="5" :offset="1" v-show="showMainCarriage">
              <h3>Main Carriage</h3>
              <a-form-item label="Qualifier">
                <a-select
                  v-decorator="['mainQualifier', { initialValue: ordersGeneralInfo[order].mainQualifier }]"
                  placeholder="Qualifier"
                  show-search
                >
                  <a-select-option v-for="qualifier in qualifiers" :key="qualifier" :value="qualifier">{{ qualifier }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Supplier">
                <a-select
                  v-decorator="['mainSupplier', { rules: mainSuppliersRules, initialValue: ordersGeneralInfo[order].mainSupplier }]"
                  placeholder="Supplier"
                  show-search
                >
                  <a-select-option v-for="supplier in suppliers" :key="supplier" :value="supplier">{{ supplier }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Vessel ID">
                <a-input
                  v-decorator="['mainVesselId', { initialValue: ordersGeneralInfo[order].mainVesselId }]"
                  placeholder="Vessel ID"
                />
              </a-form-item>
              <!-- <a-form-item label="Loading Name">
                <a-input
                  v-decorator="['mainLoadingName', { initialValue: ordersGeneralInfo[order].mainLoadingName }]"
                  placeholder="Loading Name"
                />
              </a-form-item> -->
              <a-form-item label="Loading Date">
                <a-date-picker
                  v-decorator="['mainLoadingDate', { initialValue: ordersGeneralInfo[order].mainLoadingDate }]"
                  placeholder="Loading Date"
                  valueFormat="YYYY-MM-DD[T00:00:00]"
                />
              </a-form-item>
              <a-form-item label="Loading Place">
                <!-- <a-input
                  v-decorator="['mainLoadingPlace', { initialValue: ordersGeneralInfo[order].mainLoadingPlace }]"
                  placeholder="Loading Place"
                /> -->
                <a-select
                  v-decorator="['mainLoadingPlace', { initialValue: ordersGeneralInfo[order].mainLoadingPlace }]"
                  placeholder="Loading Place"
                  show-search
                >
                  <a-select-option v-for="location in locations" :key="location" :value="location">{{ location }}</a-select-option>
                </a-select>
              </a-form-item>
               <!-- <a-form-item label="Unloading Name">
                <a-input
                  v-decorator="['mainUnloadingName', { initialValue: ordersGeneralInfo[order].mainUnloadingName }]"
                  placeholder="Unloading Name"
                />
              </a-form-item> -->
              <a-form-item label="Unloading Date">
                <a-date-picker
                  v-decorator="['mainUnloadingDate', { initialValue: ordersGeneralInfo[order].mainUnloadingDate }]"
                  placeholder="Unloading Date"
                  valueFormat="YYYY-MM-DD[T00:00:00]"
                />
              </a-form-item>
              <a-form-item label="Unloading Place">
                <!-- <a-input
                  v-decorator="['mainUnloadingPlace', { initialValue: ordersGeneralInfo[order].mainUnloadingPlace }]"
                  placeholder="Unloading Place"
                /> -->
                <a-select
                  v-decorator="['mainUnloadingPlace', { initialValue: ordersGeneralInfo[order].mainUnloadingPlace }]"
                  placeholder="Unloading Place"
                  show-search
                >
                  <a-select-option v-for="location in locations" :key="location" :value="location">{{ location }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5" :offset="1" v-show="showOnCarriage">
              <h3>On Carriage</h3>
              <a-form-item label="Qualifier">
                <a-select
                  v-decorator="['onQualifier', { initialValue: ordersGeneralInfo[order].onQualifier }]"
                  placeholder="Qualifier"
                  show-search
                >
                  <a-select-option v-for="qualifier in qualifiers" :key="qualifier" :value="qualifier">{{ qualifier }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Supplier">
                <a-input
                  v-decorator="['onSupplier', { initialValue: ordersGeneralInfo[order].onSupplier }]"
                  placeholder="Supplier"
                />
              </a-form-item>
              <!-- <a-form-item label="Loading Date">
                <a-date-picker
                  v-decorator="['onLoadingDate', { initialValue: ordersGeneralInfo[order].onLoadingDate }]"
                  placeholder="Loading Date"
                  valueFormat="YYYY-MM-DD[T00:00:00]"
                />
              </a-form-item> -->
              <a-form-item label="Loading Place">
                <!-- <a-input
                  v-decorator="['onLoadingPlace', { initialValue: ordersGeneralInfo[order].onLoadingPlace }]"
                  placeholder="Loading Place"
                /> -->
                <a-select
                  v-decorator="['onLoadingPlace', { initialValue: ordersGeneralInfo[order].onLoadingPlace }]"
                  placeholder="Loading Place"
                  show-search
                >
                  <a-select-option v-for="location in locations" :key="location" :value="location">{{ location }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Unloading Date">
                <a-date-picker
                  v-decorator="['onUnloadingDate', { initialValue: ordersGeneralInfo[order].onUnloadingDate }]"
                  placeholder="Unloading Date"
                  valueFormat="YYYY-MM-DD[T00:00:00]"
                />
              </a-form-item>
              <a-form-item label="Unloading Place">
                <!-- <a-input
                  v-decorator="['onUnloadingPlace', { initialValue: ordersGeneralInfo[order].onUnloadingPlace }]"
                  placeholder="Unloading Place"
                /> -->
                <a-select
                  v-decorator="['onUnloadingPlace', { initialValue: ordersGeneralInfo[order].onUnloadingPlace }]"
                  placeholder="Unloading Place"
                  show-search
                >
                  <a-select-option v-for="location in locations" :key="location" :value="location">{{ location }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5" :offset="1">
              <h3>Equipments</h3>
              <a-form-item label="amount">
                <a-input
                  v-decorator="['amount', { initialValue: ordersGeneralInfo[order].amount }]"
                  placeholder="Amount"
                />
              </a-form-item>
              <a-form-item label="Size">
                <a-input
                  v-decorator="['size', { initialValue: ordersGeneralInfo[order].size }]"
                  placeholder="Size"
                />
              </a-form-item>
              <a-form-item label="Type">
                <a-input
                  v-decorator="['type', { initialValue: ordersGeneralInfo[order].type }]"
                  placeholder="Type"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-button type="primary" @click="handleSaveToLive">Save to LIVE Tris</a-button>
          <a-button type="dashed" @click="handleSaveToTest">Save to Test Tris</a-button>
          <a-button type="dashed" @click="handleSaveToJson">Save to JSON</a-button>
          <a-button type="secundary" @click="handleCopyClick()">Copy data to other tabs</a-button>
        </a-form>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="Order info">
        <data-table :order="order" />
      </a-collapse-panel>

    </a-collapse>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DataTable from './DataTable'

// eslint-disable-next-line no-unused-vars
import { Modal } from 'ant-design-vue'

export default {
  components: { DataTable },
  props: {
    order: {
      type: String
    }
  },
  data () {
    return {
      departments: ['BELGIUM', 'UK', 'ILKO', 'LAUSEN', 'NAVAL NV', 'SIAK NV'],
      suppliers: ['YANG MING BELGIUM NV', 'VERBRUGGE TERMINALS', 'UNIFEEDER', 'TRANSFENNICA LTD', 'SCHENKER NV', 'SAGA WELCO AS', 'ROTRA AIR & OCEAN NV', 'REMANT GLOBE LOGISTICS NV', 'OOCL', 'NILE DUTCH BELGIUM NV', 'NEPA FORWARDING BV', 'MSC BELGIUM N.V.', 'MAERSK SEALAND', 'MAERSK LINE', 'KUEHNE + NAGEL NV', 'I FORWARDING BV', 'HAPAG-LLOYD N.V.', 'HAMBURG SUD BELGIUM', 'GRIMALDI BELGIUM N.V.', 'FESCO', 'EW LOGISTICS BVBA', 'EVERGREEN', 'CXL EUROPE / ANTWERP', 'BELGIUM', 'CMA CGM BELGIUM NV', 'ALTRIPAN NV', 'SAVINO DEL BENE BELGIUM BV', 'OCEAN NETWORK EXPRESS PTE LTD', 'COSCO BELGIUM NV'],
      countriesOfOriginOld: ['no selection', 'NL', 'BE', 'BR', 'ES', 'CN', 'RU', 'UA', 'IT', 'UY', 'CL', 'GA', 'ID', 'MY', 'MA', 'TR', 'ZA', 'LV', 'FI', 'IE', 'GB', 'CA', 'FR', 'PL', 'CZ', 'LT'],
      slas: ['IMPORT AT PORT', 'IMPORT SEAFREIGHT', 'IMPORT SEAFREIGHT Saga', 'IMPORT SEAFREIGHT UK', 'IMPORT TRUCK', 'IMPORT TRUCK T1'],
      mainSuppliersRules: [],
      qualifiers: ['Vessel', 'Truck', 'Container'],
      form: this.$form.createForm(this, { name: 'generalinformation' }),
      group: 'Import',
      showMainCarriage: false,
      showOnCarriage: false,
      customCountry: '',
      saveDoneVisible: true,
      activeKey: ['1', '2']
    }
  },
  computed: {
    ...mapState({
      hscodes: state => state.hscodes,
      countriesOfOrigin: state => state.countryOfOrigins,
      locations: state => state.locations,
      packagecodes: state => state.packagecodes,
      orders: state => state.orders,
      ordersGeneralInfo: state => state.ordersGeneralInfo,
      headers: state => state.headers,
      updated: state => state.updated,
      csvUrl: state => state.csvUrl,
      trisSaved: state => state.trisSaved,
      error: state => state.error,
      firstSlaChangeAfterCopy: state => state.firstSlaChangeAfterCopy
    }),
    ...mapGetters(['tod']),
    departement () {
      return this.$store.state.ordersGeneralInfo[this.order].departement
    },
    SLA () {
      return this.$store.state.ordersGeneralInfo[this.order].SLA
    },
    termsOfDelivery () {
      return this.$store.state.ordersGeneralInfo[this.order].termsOfDelivery
    },
    freightPrepaid () {
      return this.$store.state.ordersGeneralInfo[this.order].freightPrepaid
    },
    countryOfOrigin () {
      return this.$store.state.ordersGeneralInfo[this.order].countryOfOrigin
    },
    packageCode () {
      return this.$store.state.ordersGeneralInfo[this.order].packageCode
    },
    piStatus () {
      return this.$store.state.ordersGeneralInfo[this.order].piStatus
    },
    mainQualifier () {
      return this.$store.state.ordersGeneralInfo[this.order].mainQualifier
    },
    mainSupplier () {
      return this.$store.state.ordersGeneralInfo[this.order].mainSupplier
    },
    mainVesselId () {
      return this.$store.state.ordersGeneralInfo[this.order].mainVesselId
    },
    mainLoadingDate () {
      return this.$store.state.ordersGeneralInfo[this.order].mainLoadingDate
    },
    mainLoadingPlace () {
      return this.$store.state.ordersGeneralInfo[this.order].mainLoadingPlace
    },
    mainUnloadingDate () {
      return this.$store.state.ordersGeneralInfo[this.order].mainUnloadingDate
    },
    mainUnloadingPlace () {
      return this.$store.state.ordersGeneralInfo[this.order].mainUnloadingPlace
    },
    onQualifier () {
      return this.$store.state.ordersGeneralInfo[this.order].onQualifier
    },
    onSupplier () {
      return this.$store.state.ordersGeneralInfo[this.order].onSupplier
    },
    onHblNumber () {
      return this.$store.state.ordersGeneralInfo[this.order].onHblNumber
    },
    onLoadingDate () {
      return this.$store.state.ordersGeneralInfo[this.order].onLoadingDate
    },
    onLoadingPlace () {
      return this.$store.state.ordersGeneralInfo[this.order].onLoadingPlace
    },
    onUnloadingDate () {
      return this.$store.state.ordersGeneralInfo[this.order].onUnloadingDate
    },
    onUnloadingPlace () {
      return this.$store.state.ordersGeneralInfo[this.order].onUnloadingPlace
    },
    amount () {
      return this.$store.state.ordersGeneralInfo[this.order].amount
    },
    size () {
      return this.$store.state.ordersGeneralInfo[this.order].size
    },
    type () {
      return this.$store.state.ordersGeneralInfo[this.order].type
    }
  },
  methods: {
    handleSaveToLive (e) {
      console.log('handleSaveToLive', e)
      this.form.validateFields((error, values) => {
        if (!error) {
          console.log('save to')
          this.$store.dispatch('saveToTestLive', {
            order: this.order,
            values,
            rows: this.orders[this.order],
            allOrders: this.orders,
            headers: this.headers
          })
        } else {
          console.log('error', error)
        }
      })
    },
    handleSaveToTest (e) {
      console.log('handleSaveToTest', e)
      this.form.validateFields((error, values) => {
        if (!error) {
          console.log('save to')
          this.$store.dispatch('saveToTestTris', {
            order: this.order,
            values,
            rows: this.orders[this.order],
            allOrders: this.orders,
            headers: this.headers
          })
        } else {
          console.log('error', error)
        }
      })
    },
    handleSaveToJson (e) {
      this.form.validateFields((error, values) => {
        if (!error) {
          this.$store.dispatch('saveFile', {
            order: this.order,
            values,
            rows: this.orders[this.order],
            allOrders: this.orders,
            headers: this.headers
          })
        } else {
          console.log('error', error)
        }
      })
    },
    handleCopyClick (e) {
      this.form.validateFields((error, values) => {
        console.log('handleCopyClick form is valid', values, error)
        if (!error) {
          this.$store.dispatch('copyOrderGeneralInfo', {
            order: this.order,
            values
          })
        } else {
          console.log('handleCopyClick error', error)
        }
      })
    },
    handleFocus (e, x) {
      console.log('handlefocus', e)
      // this.open = true
    },
    handleSLAChanges (selectedOption) {
      console.log('handleSLAChanges', this.order, selectedOption)
      console.log('firstSlaChangeAfterCopy', this.firstSlaChangeAfterCopy?.[this.order])

      if (this.firstSlaChangeAfterCopy?.[this.order]) {
        if (selectedOption === 'IMPORT SEAFREIGHT') {
          this.showMainCarriage = true
          this.showOnCarriage = true
        } else if (selectedOption === 'IMPORT SEAFREIGHT Saga') {
          this.showMainCarriage = true
          this.showOnCarriage = true
        } else if (selectedOption === 'IMPORT SEAFREIGHT UK') {
          this.showMainCarriage = true
          this.showOnCarriage = true
        } else {
          this.showMainCarriage = false
          this.showOnCarriage = false
        }
        this.firstSlaChangeAfterCopy[this.order] = false
        return
      }

      this.$store.state.ordersGeneralInfo[this.order].countryOfOrigin = ''

      this.$store.state.ordersGeneralInfo[this.order].slaSelectedOption = selectedOption
      this.$store.state.ordersGeneralInfo[this.order].mainQualifier = ''
      this.$store.state.ordersGeneralInfo[this.order].mainLoadingName = ''
      this.$store.state.ordersGeneralInfo[this.order].mainUnloadingName = ''
      this.$store.state.ordersGeneralInfo[this.order].mainSupplier = ''
      this.$store.state.ordersGeneralInfo[this.order].onQualifier = ''
      this.$store.state.ordersGeneralInfo[this.order].onSupplier = ''
      this.$store.state.ordersGeneralInfo[this.order].onUnloadingPlace = ''
      this.$store.state.ordersGeneralInfo[this.order].onLoadingPlace = ''

      this.$store.state.ordersGeneralInfo[this.order].mainLoadingPlace = ''
      this.$store.state.ordersGeneralInfo[this.order].mainUnloadingPlace = ''

      this.mainSuppliersRules = []

      if (selectedOption === 'IMPORT SEAFREIGHT') {
        this.showMainCarriage = true
        this.showOnCarriage = true

        this.$store.state.ordersGeneralInfo[this.order].countryOfOrigin = 'BR'

        this.mainSuppliersRules = [{ required: true }]

        this.$store.state.ordersGeneralInfo[this.order].mainQualifier = 'Vessel'
        this.$store.state.ordersGeneralInfo[this.order].mainLoadingPlace = 'Antwerp'
        this.$store.state.ordersGeneralInfo[this.order].mainUnloadingPlace = 'berth S869'

        this.$store.state.ordersGeneralInfo[this.order].onQualifier = 'Container'
        this.$store.state.ordersGeneralInfo[this.order].onSupplier = 'Nova Natie Terminals NV'
        this.$store.state.ordersGeneralInfo[this.order].onLoadingPlace = 'berth S869'
        this.$store.state.ordersGeneralInfo[this.order].onUnloadingPlace = 'berth 420'
      } else if (selectedOption === 'IMPORT SEAFREIGHT Saga') {
        this.showMainCarriage = true
        this.showOnCarriage = true

        this.$store.state.ordersGeneralInfo[this.order].countryOfOrigin = 'CN'

        this.$store.state.ordersGeneralInfo[this.order].mainQualifier = 'Vessel'
        this.$store.state.ordersGeneralInfo[this.order].mainSupplier = 'SAGA WELCO AS'
        this.$store.state.ordersGeneralInfo[this.order].mainLoadingPlace = 'Lianyungang (CN LYG)'
        this.$store.state.ordersGeneralInfo[this.order].mainUnloadingPlace = 'berth 1215'

        this.$store.state.ordersGeneralInfo[this.order].onQualifier = 'Truck'
        this.$store.state.ordersGeneralInfo[this.order].onSupplier = 'Altripan NV'
        this.$store.state.ordersGeneralInfo[this.order].onLoadingPlace = 'berth 1215'
        this.$store.state.ordersGeneralInfo[this.order].onUnloadingPlace = 'Antwerp'
      } else if (selectedOption === 'IMPORT SEAFREIGHT UK') {
        console.log('show carriage')
        this.showMainCarriage = true
        this.showOnCarriage = true
        this.$store.state.ordersGeneralInfo[this.order].mainQualifier = 'Vessel'
        this.$store.state.ordersGeneralInfo[this.order].mainSupplier = 'SAGA WELCO AS'
        this.$store.state.ordersGeneralInfo[this.order].mainLoadingPlace = 'Lianyungang (CN LYG)'
        this.$store.state.ordersGeneralInfo[this.order].mainUnloadingPlace = 'PORT OF TILBURY LONDON LTD'

        this.$store.state.ordersGeneralInfo[this.order].onQualifier = 'Truck'
        this.$store.state.ordersGeneralInfo[this.order].onSupplier = 'ALTRIPAN U.K. LTD'
        this.$store.state.ordersGeneralInfo[this.order].onLoadingPlace = 'PORT OF TILBURY LONDON LTD'
        this.$store.state.ordersGeneralInfo[this.order].onUnloadingPlace = 'PORT OF TILBURY LONDON LTD'
      } else {
        this.showMainCarriage = false
        this.showOnCarriage = false
      }
    },
    handleCountrySearch (value) {
      this.customCountry = value
    },
    inputCountryDown (value) {
      if (value.keyCode === 13) {
        console.log('before', this.countriesOfOrigin)
        this.countriesOfOrigin.push(this.customCountry)
        console.log('after', this.countriesOfOrigin)
      }
    }
  },
  mounted () {
    console.log('tod', this.tod(this.order))
    this.handleSLAChanges(this.SLA)
  },
  watch: {
    updated: function (val, oldVal) {
      console.log('updated', this.order, val, this.$store.state.ordersGeneralInfo[this.order].slaSelectedOption, this.SLA)
      if (this.SLA) {
        this.handleSLAChanges(this.SLA)
      }
    },
    csvUrl: function (val, oldVal) {
      console.log('order vue', val, oldVal)
      window.location = val
    },
    trisSaved: function (val, oldVal) {
      console.log('tris saved', val, oldVal)
      if (val !== oldVal && val) {
        // const h = this.$createElement
        const mdl = this.$success({
          title: 'Save to TRIS',
          content: 'Data was saved to TRIS'
        })
        setTimeout(() => {
          console.log('mdl', mdl)
          mdl.destroy()
          this.$router.push('/')
        }, 1000)
      }
    }
    // error: function (val, oldVal) {
    //   console.log('new error', val, oldVal)
    //   if (val && val !== oldVal) {
    //     // eslint-disable-next-line no-unused-vars
    //     const mdl = this.$error({
    //       title: 'Error !!!',
    //       content: val
    //     })
    //   }
    // }
  }
}
</script>

<style>
  .ant-collapse-content {
    overflow: scroll;
  }
</style>
