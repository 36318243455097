<template>
  <div id="main">
    <a-layout>
      <a-layout-content>
        <a-row style="text-align: center;"><a href="/select">use Ordas service</a></a-row>
        <a-row>
          <a-col :span="22" :offset="1" class="h100">
            <a-upload-dragger
              :action="uploadUrl"
              :customRequest="customRequest"
              name="file"
              :multiple="false"
              :show-upload-list="false"
              @change="handleChange"
              :style="{ display: displayUpload }"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p class="ant-upload-hint"></p>
            </a-upload-dragger>
          </a-col>
        </a-row>
        <a-row style="text-align: center">&nbsp;v2.4</a-row>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
const { mapActions, mapState } = require('vuex')

export default {
  data: function () {
    return {
      displayUpload: 'none'
    }
  },
  mounted: function () {
    this.getUploadUrl()
  },
  methods: {
    handleChange: function (info) {
      // console.log('handleChange', info, info.file.originFileObj.path)
    },
    customRequest: function ({ onSuccess, onError, file }) {
      // https://dejanvasic.wordpress.com/2020/09/10/ant-design-upload-to-s3-bucket-and-graphql/
      const xhr = new XMLHttpRequest()
      xhr.open('PUT', this.uploadUrl)
      xhr.onreadystatechange = () => {
        console.log(xhr.readyState, xhr.status)
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            this.fileUploaded()
            onSuccess(null, file)
          } else {
            onError(xhr.responseText)
          }
        }
      }
      xhr.send(file)
    },
    // eslint-disable-next-line dot-notation
    ...mapActions(['getUploadUrl', 'fileUploaded'])
  },
  computed: {
    ...mapState({
      uploadUrl: state => state.uploadUrl,
      orders: state => state.orders
    })
  },
  watch: {
    uploadUrl: function (val, oldVal) {
      this.displayUpload = 'inline'
    },
    orders: function (val, oldVal) {
      this.$router.push('/tabpage')
    }
  }
}
</script>

<style scoped>
  div#main{
    height: 100%;
  }

  .ant-layout{
    height: 100%;
  }

  .ant-layout-content{
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .ant-row{
    height: 5%;
  }

  .ant-row:nth-child(2){
    flex-grow: 1;
  }

  .h100{
    height: 100%;
  }
</style>
