const XLSX = require('xlsx')
const axios = require('axios')
const { getJsDateFromExcel } = require('excel-date-to-js')

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
let commodities
let hscodes
const thirdparties = []
let countryOfOrigins
let locations

const getBotanics = async (url) => {
  try {
    const s3Response = await axios.get(url, { responseType: 'arraybuffer' })

    const data = new Uint8Array(s3Response.data)
    // console.log('data', data)
    // eslint-disable-next-line no-unused-vars
    const workbook = XLSX.read(data, { type: 'array' })
    const worksheets = workbook.Sheets
    const firstWorksheet = worksheets[Object.keys(worksheets)[0]]

    const lastRow = parseInt(firstWorksheet['!ref'].split(':')[1].replace(/^\D+/g, ''))

    const botanics = []
    for (let i = 1; i <= lastRow; i++) {
      botanics.push(firstWorksheet['A' + i].w.trim())
    }

    botanics.sort()

    console.log('botanics', botanics)

    return (botanics)
  } catch (error) {
    throw new Error(error)
  }
}

const getThirdParties = async (url) => {
  try {
    const s3Response = await axios.get(url, { responseType: 'arraybuffer' })

    const data = new Uint8Array(s3Response.data)
    // console.log('data', data)
    // eslint-disable-next-line no-unused-vars
    const workbook = XLSX.read(data, { type: 'array' })
    const worksheets = workbook.Sheets
    const firstWorksheet = worksheets[Object.keys(worksheets)[0]]

    const lastRow = parseInt(firstWorksheet['!ref'].split(':')[1].replace(/^\D+/g, ''))

    // thirdparties = []
    for (let i = 2; i <= lastRow; i++) {
      thirdparties.push({
        code: firstWorksheet['B' + i].w.trim(),
        name: firstWorksheet['C' + i].w.trim()
      })
    }

    thirdparties.sort()

    console.log('thirdparties', thirdparties)

    return (thirdparties)
  } catch (error) {
    throw new Error(error)
  }
}

const getCountryOfOrigins = async (url) => {
  try {
    const s3Response = await axios.get(url, { responseType: 'arraybuffer' })

    const data = new Uint8Array(s3Response.data)
    // console.log('data', data)
    // eslint-disable-next-line no-unused-vars
    const workbook = XLSX.read(data, { type: 'array' })
    const worksheets = workbook.Sheets
    const firstWorksheet = worksheets[Object.keys(worksheets)[0]]

    const lastRow = parseInt(firstWorksheet['!ref'].split(':')[1].replace(/^\D+/g, ''))

    countryOfOrigins = []
    for (let i = 2; i <= lastRow; i++) {
      countryOfOrigins.push({
        code: firstWorksheet['A' + i].w.trim()
      })
    }

    console.log('countryOfOrigins', countryOfOrigins)

    return (countryOfOrigins)
  } catch (error) {
    throw new Error(error)
  }
}

const getLocations = async (url) => {
  try {
    const s3Response = await axios.get(url, { responseType: 'arraybuffer' })

    const data = new Uint8Array(s3Response.data)
    // console.log('data', data)
    // eslint-disable-next-line no-unused-vars
    const workbook = XLSX.read(data, { type: 'array' })
    const worksheets = workbook.Sheets
    const firstWorksheet = worksheets[Object.keys(worksheets)[0]]

    const lastRow = parseInt(firstWorksheet['!ref'].split(':')[1].replace(/^\D+/g, ''))

    locations = []
    for (let i = 2; i <= lastRow; i++) {
      locations.push({
        location: firstWorksheet['A' + i].w.trim()
      })
    }

    console.log('locations', locations)

    return (locations)
  } catch (error) {
    throw new Error(error)
  }
}

const getHSCodes = async (url) => {
  try {
    const s3Response = await axios.get(url, { responseType: 'arraybuffer' })

    const data = new Uint8Array(s3Response.data)

    const workbook = XLSX.read(data, { type: 'array' })
    const worksheets = workbook.Sheets
    const firstWorksheet = worksheets[Object.keys(worksheets)[0]]

    const lastRow = parseInt(firstWorksheet['!ref'].split(':')[1].replace(/^\D+/g, ''))

    hscodes = []
    for (let i = 1; i <= lastRow; i++) {
      hscodes.push(firstWorksheet['A' + i].w.trim())
    }

    hscodes.sort()

    return (hscodes)
  } catch (error) {
    throw new Error(error)
  }
}

const getCommodities = async (url) => {
  try {
    const s3Response = await axios.get(url, { responseType: 'arraybuffer' })

    const data = new Uint8Array(s3Response.data)

    const workbook = XLSX.read(data, { type: 'array' })
    const worksheets = workbook.Sheets
    const firstWorksheet = worksheets[Object.keys(worksheets)[0]]

    const lastRow = parseInt(firstWorksheet['!ref'].split(':')[1].replace(/^\D+/g, ''))
    commodities = []
    for (let i = 2; i <= lastRow; i++) {
      commodities.push({
        artikel: firstWorksheet['A' + i].w.trim(),
        omschrijving: firstWorksheet['B' + i].w.trim(),
        omschrij: firstWorksheet['C' + i].w.trim()
      })
    }

    commodities.lookup = function (artikel, omschrijving) {
      let newOmschrijving = commodities.find(element => element.artikel === artikel)
      console.log('artikel', artikel, commodities.length)
      console.log('omschrijving', omschrijving)
      console.log('newOmschrijving', newOmschrijving)

      if (!newOmschrijving) {
        newOmschrijving = commodities.find(element => element.omschrijving === omschrijving)
      }

      if (!newOmschrijving) {
        newOmschrijving = { omschrij: 'MULTIPLEX' } // trigger alert
      }

      console.log('newOmschrijving 2', newOmschrijving)

      return newOmschrijving.omschrij
    }

    return (commodities)
  } catch (error) {
    throw new Error(error)
  }
}

const convertExcelFromFile = async (url) => {
  try {
    const s3Response = await axios.get(url, { responseType: 'arraybuffer' })

    const data = new Uint8Array(s3Response.data)

    const workbook = XLSX.read(data, { type: 'array' })
    const worksheets = workbook.Sheets
    const firstWorksheet = worksheets[Object.keys(worksheets)[0]]

    const lastColumn = firstWorksheet['!ref'].split(':')[1].replace(/[0-9]+/g, '')
    const lastRow = parseInt(firstWorksheet['!ref'].split(':')[1].replace(/^\D+/g, ''))

    const columns = buildColumnName(lastColumn)
    // const extraColumns = ['Package code', 'Gross Weight', 'Gross weight unit', 'Net weight', 'Net weight unit', 'Invoice', 'Botanical name', 'Certification', 'Claim']
    // const extraColumns = ['HS Code', 'Botanical name', 'Gross Weight', 'Gross weight unit', 'Net weight', 'Net weight unit', 'Reference qualifier', 'Certification', 'Claim']
    const extraColumns = ['HS Code', 'Botanical name', 'Gross Weight', 'Gross weight unit', 'Net weight', 'Invoice', 'Net weight unit', 'Reference qualifier', 'Certification', 'Claim']

    // console.log('columns', columns)
    let headers = []
    columns.forEach(c => {
      console.log('c', c, firstWorksheet[c + '1'])
      if (firstWorksheet[c + '1']) {
        headers.push(firstWorksheet[c + '1'].v.trim())
      }
    })
    // console.log('headers', headers)

    const rows = []
    for (let i = 2; i <= lastRow; i++) {
      const row = {}

      headers.forEach((h, index) => {
        // if (index === 11) {
        //   console.log('header', index, h, i, firstWorksheet[alphabet[index] + i])
        // }
        // console.log('rrrr', index, i, firstWorksheet[alphabet[index] + i])
        if (firstWorksheet[alphabet[index] + i]) {
          row[h] = firstWorksheet[alphabet[index] + i].v
        } else {
          row[h] = ''
        }
        // if (index === 0) {
        //   console.log('rowh', row[h])
        // }
        if (typeof row[h] === 'string') {
          row[h] = row[h].trim()
        }
        // if (index === 0) {
        //   console.log('rowh2', row[h])
        // }
      })

      rows.push(row)
    }

    let consignments = []
    let orders = {}

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]

      let order = ''
      let oorsprong = ''
      try {
        oorsprong = row.Oorsprong
        const regex = /lingen:[\s0-9]/g
        oorsprong = oorsprong.substring(oorsprong.search(regex))
        oorsprong = oorsprong.substring(order.search(/[0-9]/g))

        order = row['Ordernr. oorsprong']

        console.log('AAA order', order, oorsprong)
      } catch (error) {
        console.log('error2', error)
        throw new Error(error)
      }

      if (!orders[order] && order !== '') {
        orders[order] = []
      }

      // console.log('row', i, row)

      // GET CLAIM
      let claim = ''
      // GET Certification
      let certification = ''

      // row.Omschrijvingsblok.replace(/((FSC|PEFC|mix)[\s0-9]*%)|(mix credit)/gi, (w) => {
      // console.log('///--------------------------------------')
      row.Omschrijvingsblok.replace(/(FSC)/gi, (w) => {
        certification = w
        if (certification === '') {
          certification = 'No Selection'
        }

        claim = w
        // console.log('claim 1', claim)

        // get percentage of fsc
        // row.Omschrijvingsblok.replace(/([0-9]*%)|(mix)/gi, (w) => {
        row.Omschrijvingsblok.replace(/(mix [0-9]*%)|([0-9]*%)|(mix)/gi, (w) => {
          if (w.toLowerCase() === 'mix') {
            w = 'Mix Credit'
          }
          claim += '|' + w
          // console.log('claim 2', claim)
        })
      })

      if (claim === '') {
        row.Omschrijvingsblok.replace(/(FSC|PEFC|mix|(mix credit)|(v-legal))/gi, (w) => {
          certification = w

          claim = w
          console.log('claim 3', claim)

          row.Omschrijvingsblok.replace(/([0-9]*%)|(mix)/gi, (w) => {
            if (w.toLowerCase() === 'mix') {
              w = 'Mix Credit'
            }
            claim += '|' + w
            console.log('claim 4', claim)
          })
        })
      }

      if (certification === '') {
        certification = 'No Selection'
      }
      // claim = 'claim' + i

      // console.log('row.omsc', row.Omschrijvingsblok)
      // console.log('--------------------------------------///')

      // row.Omschrijvingsblok.replace(/(FSC|PEFC|V-Legal|No Selection)/gi, (w) => {
      //   certification = w
      // })

      row.Omschrijvingsblok = commodities.lookup(row.Artikelnummer, row.Omschrijvingsblok)

      row['Netto lijntotaal'] = parseFloat(String(row['Netto lijntotaal']).replace(',', ''))

      row.Volume = parseInt(row['Gelev.Eenh. Voorraad'] * row.Dikte * row.Lengte * row['Aant. per verpak'] * row.Breedte) / 1000000000

      consignments.push(row['Ordernr. oorsprong'] + claim)

      // row['Pacakge code'] = 'CR - Crate'
      // row['Gross Weight'] = 50
      row['Gross weight unit'] = 'kg'
      // row['Net weight'] = 50
      row['Net weight unit'] = 'kg'
      // row.Invoice = ''
      // if (row['Marks numbers']) {
      //   row.Invoice = row['Marks numbers']
      // }
      row['Marks numbers'] = row.Invoice
      row['Reference qualifier'] = 'invoice'
      row['Botanical name'] = ''
      row.Certification = certification
      row.Claim = claim
      row.ClaimConsignment = claim
      row['HS Code'] = ''

      row.Claim = row.Claim.replace(/(.*\|)/gi, '')

      if (row.Naam === 'URUPLY S.A  LUMIN') {
        row.Naam = 'Lumin Uruply S.A.'
      }

      const existingNaam = thirdparties.find(tp => tp.name.toUpperCase() === row.Naam.toUpperCase())
      console.log('CHECK Naam', `|${row.Naam}|`, existingNaam, thirdparties.length)
      if (!existingNaam) {
        row.Naam = ''
      }

      if (orders[order]) {
        orders[order].push(row)
      }
    }

    consignments = [...new Set(consignments)]

    console.log('consignments', consignments)

    const filteredOrders = {}
    const ordersGeneralInfo = {}

    for (const order in orders) {
      filteredOrders[order] = []
      ordersGeneralInfo[order] = {
        test: true,
        piStatus: 'Varend'
      }
      if (orders[order][0].Leveringsvoorw) {
        ordersGeneralInfo[order].termsOfDelivery = orders[order][0].Leveringsvoorw
        ordersGeneralInfo[order].mainVesselId = orders[order][0]['Onze referentie']
      }
      if (orders[order][0]['Lev.datum']) {
        let levDate = getJsDateFromExcel(orders[order][0]['Lev.datum'])
        levDate = levDate.getFullYear() + '-' + addZero(levDate.getMonth() + 1) + '-' + addZero(levDate.getDate())

        ordersGeneralInfo[order].mainUnloadingDate = levDate

        console.log('getJsDateFromExcel(44300)', getJsDateFromExcel(orders[order][0]['Lev.datum']), levDate)
      }

      for (let i = 0; i < consignments.length; i++) {
        const consignment = consignments[i]
        const filtered = orders[order].filter(row => row['Ordernr. oorsprong'] + row.ClaimConsignment === consignment)
        if (filtered.length > 0) {
          addColumnReducer(filtered, 'Gelev.Eenh. Voorraad')
          addColumnReducer(filtered, 'Netto lijntotaal')
          addColumnReducer(filtered, 'Volume')

          filteredOrders[order].push(filtered[0])
        }
      }
    }

    orders = filteredOrders

    console.log('orders', orders)
    Object.keys(orders).forEach(order => {
      console.log('order', order)
      orders[order].forEach(o => {
        console.log('o', o)
        o.Volume = o.Volume?.toFixed(3)
        o['Netto lijntotaal'] = o['Netto lijntotaal']?.toFixed(2)
      })
    })

    headers = [...extraColumns, ...headers.slice(0, 2), ...headers.slice(2), 'Volume']

    console.log('READ', orders)

    return { headers, orders, ordersGeneralInfo }
  } catch (error) {
    throw new Error(error)
  }
}

const getDataFromOrdas = async (registrationNumbers) => {
  console.log('api getDataFromOrda', registrationNumbers)
  axios.defaults.headers.common['x-api-key'] = 'UE9tEXX5VryR4zro1ALj6GtrpUm9pbmv19bbUB60'
  const ordasData = await axios.post('https://51zmueuhi8.execute-api.eu-west-1.amazonaws.com/ae/altripan/ordas/ontvangstlijst', {
    registrationNumber: registrationNumbers,
    registrationType: [
      220
    ]
  }, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'UE9tEXX5VryR4zro1ALj6GtrpUm9pbmv19bbUB60'
    }
  })

  console.log('ordasData', ordasData.data)

  const extraColumns = ['HS Code', 'Botanical name', 'Gross Weight', 'Gross weight unit', 'Net weight', 'Net weight unit', 'Reference qualifier', 'Invoice', 'Certification', 'Claim']
  const headers = ['Onze referentie', 'Ref. kl/Lev', 'Order (.volgnr)', 'Artikelnummer', 'Naam', 'Oorsprong', 'Omschrijvingsblok', 'Munt', 'Ordernr. oorsprong', 'Gelev.Eenh. Voorraad', 'Breedte', 'Dikte', 'Lengte', 'Aant. per verpak', 'Lev.datum', 'Netto lijntotaal', 'Leveringsvoorw', 'Vessel en voyage number', 'Marks numbers', 'Volume']
  const dataHeaders = ['ourReference', 'customerSupplierReference', 'registrationNumber', 'itemNumber', 'customerSupplierName', 'originalRegistrationType', 'description', 'currency', 'linkedRegistrationNumber', 'deliveredQuantityStockUnit', 'itemWidth', 'itemThickness', 'itemLength', 'numberPerPackage', 'deliveryDate2Until', 'netLineAmount', 'incotermsDescription', 'vesselVoyageNumber', 'marksAndNumbers']

  let consignments = []
  let orders = {}

  for (let i = 0; i < ordasData.data.length; i++) {
    const data = ordasData.data[i]
    const d = {}

    // console.log('data', i, data)
    for (let j = 0; j < headers.length; j++) {
      // console.log('j', headers[j], dataHeaders[j])
      d[headers[j]] = data[dataHeaders[j]]
      // break
    }

    console.log('d', d)

    d['Ordernr. oorsprong'] = parseInt(d['Ordernr. oorsprong'])
    d['Order (.volgnr)'] = parseInt(d['Order (.volgnr)'])

    const order = d['Ordernr. oorsprong']
    if (!orders[order] && order !== '') {
      orders[order] = []
    }

    let claim = ''
    let certification = ''

    d.Omschrijvingsblok.replace(/(FSC)/gi, (w) => {
      certification = w
      if (certification === '') {
        certification = 'No Selection'
      }

      claim = w

      d.Omschrijvingsblok.replace(/(mix [0-9]*%)|([0-9]*%)|(mix)/gi, (w) => {
        if (w.toLowerCase() === 'mix') {
          w = 'Mix Credit'
        }
        claim += '|' + w
      })
    })

    if (claim === '') {
      d.Omschrijvingsblok.replace(/(FSC|PEFC|mix|(mix credit)|(v-legal))/gi, (w) => {
        certification = w

        claim = w

        d.Omschrijvingsblok.replace(/([0-9]*%)|(mix)/gi, (w) => {
          if (w.toLowerCase() === 'mix') {
            w = 'Mix Credit'
          }
          claim += '|' + w
        })
      })
    }

    if (certification === '') {
      certification = 'No Selection'
    }

    d.Omschrijvingsblok = commodities.lookup(d.Artikelnummer, d.Omschrijvingsblok)

    d['Netto lijntotaal'] = parseFloat(String(d['Netto lijntotaal']).replace(',', ''))

    d.Volume = parseInt(d['Gelev.Eenh. Voorraad'] * d.Dikte * d.Lengte * d['Aant. per verpak'] * d.Breedte) / 1000000000
    console.log('d.omschrijving', d.Omschrijvingsblok, ' | ', certification, claim)

    consignments.push(d['Ordernr. oorsprong'] + claim)

    d['Gross weight unit'] = 'kg'
    d['Net weight unit'] = 'kg'
    d['Reference qualifier'] = 'invoice'
    d['Botanical name'] = ''
    d.Invoice = data.externalComment
    d['Marks numbers'] = d.Invoice
    d.Certification = certification
    d.Claim = claim
    d.ClaimConsignment = claim
    d['HS Code'] = ''

    console.log('new d', d)

    d.Claim = d.Claim.replace(/(.*\|)/gi, '')
    console.log('NAAM', d.Naam)
    if (d.Naam === 'URUPLY S.A  LUMIN') {
      d.Naam = 'Lumin Uruply S.A.'
    }
    const existingNaam = thirdparties.find(tp => tp.name.toUpperCase() === d.Naam.toUpperCase())
    console.log('CHECK Naam', `|${d.Naam}|`, existingNaam, thirdparties.length)
    if (!existingNaam) {
      d.Naam = ''
    }

    if (orders[order]) {
      orders[order].push(d)
    }
    // break
  }

  console.log('orders', orders)

  consignments = [...new Set(consignments)]

  console.log('consignments', consignments)
  // overgenomen tot regel 375

  const filteredOrders = {}
  const ordersGeneralInfo = {}

  for (const order in orders) {
    filteredOrders[order] = []
    ordersGeneralInfo[order] = {
      test: true,
      piStatus: 'Varend'
    }
    if (orders[order][0].Leveringsvoorw) {
      ordersGeneralInfo[order].termsOfDelivery = orders[order][0].Leveringsvoorw
      ordersGeneralInfo[order].mainVesselId = orders[order][0]['Onze referentie']
    }
    if (orders[order][0]['Lev.datum']) {
      const levDate = (orders[order][0]['Lev.datum'])
      // levDate = levDate.getFullYear() + '-' + addZero(levDate.getMonth() + 1) + '-' + addZero(levDate.getDate())

      ordersGeneralInfo[order].mainUnloadingDate = levDate

      // console.log('getJsDateFromExcel(44300)', getJsDateFromExcel(orders[order][0]['Lev.datum']), levDate)
    }

    for (let i = 0; i < consignments.length; i++) {
      const consignment = consignments[i]
      const filtered = orders[order].filter(row => row['Ordernr. oorsprong'] + row.ClaimConsignment === consignment)
      if (filtered.length > 0) {
        addColumnReducer(filtered, 'Gelev.Eenh. Voorraad')
        addColumnReducer(filtered, 'Netto lijntotaal')
        addColumnReducer(filtered, 'Volume')

        filteredOrders[order].push(filtered[0])
      }
    }
  }

  orders = filteredOrders

  console.log('orders', orders)
  Object.keys(orders).forEach(order => {
    console.log('order', order)
    orders[order].forEach(o => {
      console.log('o', o)
      o.Volume = o.Volume?.toFixed(3)
      o['Netto lijntotaal'] = o['Netto lijntotaal']?.toFixed(2)
    })
  })

  headers.unshift(...extraColumns)
  // console.log('headers', headers)

  return { headers, orders, ordersGeneralInfo }
}

const addZero = (value) => {
  if (value < 10) {
    return '0' + value
  }
  return value
}

// ////////////////////////////////////////
const buildColumnName = (lastColumn) => {
  const columns = []
  let index = 0

  let alphabetIndex = 0
  let prefix = ''
  let prefixIndex = 0

  do {
    columns.push(prefix + alphabet[alphabetIndex++])
    if (alphabetIndex === alphabet.length) {
      alphabetIndex = 0
      prefix = alphabet[prefixIndex]
      prefixIndex++
    }
    index++
  } while (columns.slice(-1)[0] !== lastColumn && index < 100)
  return columns
}

const addColumnReducer = (arr, column) => {
  arr[0][column] = arr.reduce((acc, cu) => {
    if (typeof acc[column] === 'number') {
      acc = parseFloat(acc[column])
    }
    return acc + parseFloat(cu[column])
  }, 0)
}

// ////////////////////////////////////////

exports.getBotanics = getBotanics
exports.getHSCodes = getHSCodes
exports.getCommodities = getCommodities
exports.getThirdParties = getThirdParties
exports.getCountryOfOrigins = getCountryOfOrigins
exports.getLocations = getLocations

exports.convertExcelFromFile = convertExcelFromFile
exports.getDataFromOrdas = getDataFromOrdas
