<template>
  <div id="main">
    <a-tabs  @tabClick="tabClick">
      <a-tab-pane :tab="order" v-for="order in ordersArray" :key="order">
        <order :order="order" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Order from './../components/Order'

export default {
  components: { Order },
  computed: {
    ...mapGetters(['ordersArray']),
    ...mapState({
      error: state => state.error
    })
  },
  methods: {
    tabClick: function (e) {
      console.log('tab lcicked', e)
    }
  },
  mounted () {
    console.log('mounted', this.ordersArray)
    if (this.ordersArray.length === 0) {
      this.$router.push('/')
    }
  },
  watch: {
    error: function (val, oldVal) {
      console.log('new error', val, oldVal)
      if (val && val !== oldVal) {
        // eslint-disable-next-line no-unused-vars
        const mdl = this.$error({
          title: 'Error !!!',
          content: val
        })
      }
    }
  }
}
</script>
