<template>
  <div id="main">
    <a-layout>
      <a-layout-content>
        <a-row>
          <a-col :span="24">
            <a-table v-for="n in 6" :key="n" :columns="colummsFromHeaders.slice((n-1)*6,(n-1)*6+6)" :data-source="dataFromRows" :pagination="false">
              <!-- <span slot="packagecodes" slot-scope="text, record, colleke, name">
                <a-select style="width: 125px" :key="spackage" :defaultValue="text" @change="handleInputChange(record.key, colleke, name, $event)">
                  <a-select-option v-for="packagecode in packagecodes" :key="packagecode" :value="packagecode">{{packagecode}}</a-select-option>
                </a-select>
              </span> -->
              <span slot="botanics" slot-scope="text, record, colleke, name">
                <a-select show-search :autoClearSearchValue="false" style="width: 200px" :key="sbotanics" :defaultValue="text" @search="handleBotanicSearch" @inputKeydown="inputBotanicDown" @change="handleInputChange(record.key, colleke, name, $event)" :value="text">
                  <a-select-option v-for="botanic in botanics" :key="botanic" :value="botanic" show-search>{{botanic}}</a-select-option>
                </a-select>
              </span>
              <span slot="hscodes" slot-scope="text, record, colleke, name">
                <a-select show-search :autoClearSearchValue="false" style="width: 200px" :key="sbotanics" :defaultValue="text" @search="handleHSCodeSearch" @inputKeydown="inputHSCodeDown" @change="handleInputChange(record.key, colleke, name, $event)" :value="text">
                  <a-select-option v-for="hscode in hscodes" :key="hscode" :value="hscode" show-search>{{hscode}}</a-select-option>
                </a-select>
              </span>
              <span slot="naam" slot-scope="text, record, colleke, name">
                <a-select
                  show-search
                  required
                  :autoClearSearchValue="false"
                  style="width: 200px"
                  :key="sbotanics"
                  :defaultValue="text"
                  @search="handleThirdpartySearch"
                  @change="handleInputChange(record.key, colleke, name, $event)"
                  :value="text"
                >
                  <a-select-option v-for="(thirdparty, index) in thirdpartiesList" :key="index" :value="thirdparty" show-search> {{ thirdparty }}</a-select-option>
                </a-select>
              </span>
              <span slot="textinput" slot-scope="text, record, colleke, name">
                <a-input :value="text" style="width: 200px"  @change="handleInputChange(record.key, colleke, name, $event)" />
              </span>
            </a-table>
          </a-col>
        </a-row>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    order: {
      type: String
    }
  },
  data () {
    return {
      autoCopyContent: false,
      sbotanics: 0,
      spackage: 0,
      selectedBotanics: [],
      customBotanic: '',
      customHSCode: ''
    }
  },
  computed: {
    ...mapState({
      orders: state => state.orders,
      headers: state => state.headers,
      // packagecodes: state => state.packagecodes,
      botanics: state => state.botanics,
      hscodes: state => state.hscodes
    }),
    thirdpartiesList () {
      return [...new Set(this.$store.state.thirdparties.map(tp => tp.name))]
    },
    filteredBotanics: function () {
      return this.botanics.filter(b => !this.selectedBotanics.includes(b))
    },
    dataFromRows: function () {
      const data = []
      for (let i = 0; i < this.orders[this.order].length; i++) {
        // eslint-disable-next-line no-unused-vars
        const row = this.orders[this.order][i]
        // console.log('row', row)

        const dataItem = {}
        this.headers.forEach((header, index) => {
          if (header === 'Kleur') {
            header = 'Kleur' + index
          }
          dataItem[header.toLowerCase().replace(' ', '_')] = row[header]
        })

        data.push({
          key: i,
          ...dataItem
        })
      }

      // console.log('data', data)
      return data
    },
    colummsFromHeaders: function () {
      const columns = []
      console.log('lengte', this.headers.length)
      for (let i = 0; i < this.headers.length; i++) {
        let header = this.headers[i]
        if (header === 'Kleur') {
          header = 'Kleur' + i
        }
        const column = {
          dataIndex: header.toLowerCase().replace(' ', '_'),
          key: header.toLowerCase().replace(' ', '_'),
          title: header,
          index: i
        }
        if (header === 'Botanical name') {
          column.scopedSlots = { customRender: 'botanics' }
        } else if (header === 'HS Code') {
          column.scopedSlots = { customRender: 'hscodes' }
        } else if (header === 'Net weight') {
          column.scopedSlots = { customRender: 'textinput', name: 'netWeight', colleke: i }
        } else if (header === 'Naam') {
          column.scopedSlots = { customRender: 'naam' }
        } else {
          column.scopedSlots = { customRender: 'textinput' }
        }
        // if (header === 'Gross Weight') {
        //   column.scopedSlots = { customRender: 'textinput' }
        // }
        // if (header === 'Gross weight unit') {
        //   column.scopedSlots = { customRender: 'textinput' }
        // }
        // if (header === 'Net weight') {
        //   column.scopedSlots = { customRender: 'textinput', name: 'netWeight', colleke: i }
        // }
        // if (header === 'Net weight unit') {
        //   column.scopedSlots = { customRender: 'textinput' }
        // }
        columns.push(column)
      }
      // console.log('columns', columns)
      return columns
    }
  },
  // mounted () {
  //   setInterval(() => {
  //     console.log('datable mounted hscodes', this.hscodes)
  //   }, 1000)
  // },
  methods: {
    ...mapActions(['addBotanic', 'addHSCode']),
    copyContentDown () {
      console.log('copyContentDown')
      this.autoCopyContent = true

      const changeObj = {
        order: this.order,
        noUpdate: true,
        autoCopyContent: this.autoCopyContent
      }
      this.$store.dispatch('updateColumnInOrderRow', changeObj)
    },
    handleBotanicSearch (value) {
      console.log('handle search', value)
      this.customBotanic = value
    },
    inputBotanicDown (value) {
      if (value.keyCode === 13) {
        console.log('is enter', this.customBotanic)
        this.addBotanic(this.customBotanic)
      }
    },
    handleHSCodeSearch (value) {
      console.log('handle search', value)
      this.customHSCode = value
    },
    inputHSCodeDown (value) {
      if (value.keyCode === 13) {
        console.log('is enter', this.customHSCode)
        this.addHSCode(this.customHSCode)
      }
    },
    handleThirdpartySearch (value) {
      console.log('handle thirdparty search', value)
    },
    handleInputChange (key, column, name, event) {
      console.log('handleInputChange', key, column, name, event)
      let value
      if (event.target) {
        value = event.target.value
      } else {
        value = event
      }

      if (this.autoCopyContent && key !== 0) {
        console.log('autoCopyContent', this.autoCopyContent)
        this.autoCopyContent = false
      }

      const changeObj = {
        order: this.order,
        rowIndex: key,
        colIndex: name.index,
        value: value,
        autoCopyContent: this.autoCopyContent
      }
      this.$store.dispatch('updateColumnInOrderRow', changeObj)
    }
  }
}
</script>
