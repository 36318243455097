const api = require('./api')

const writeExcel = async (existingOrder, saveToTest, saveToLive, thirdparties) => {
  const order = JSON.parse(JSON.stringify(existingOrder))
  // console.log('write excell order', order, thirdparties)

  const values = order.values
  const orders = []

  Object.keys(order.allOrders).forEach((orderNr, index) => {
    const orderRows = order.allOrders[orderNr]
    if (index === 0) {
      // console.log('orderRows', orderRows)
    }
    let hasError = false
    orderRows.forEach(or => {
      // console.log('hscode', or['HS Code'])
      or['HS Code'] = or['HS Code'].replace(/\s/g, '')
      // console.log('hscode changed', or['HS Code'])
      or.original_naam = or.Naam
      or.Naam = thirdparties.find(tp => tp.name.toUpperCase() === or.Naam.toUpperCase())?.code

      or['Vessel en voyage number'] = `${or['Vessel en voyage number']}`
      or['Marks numbers'] = `${or['Marks numbers']}`

      console.log('OR', or.Naam)
      if (!or.Naam) {
        hasError = true
      }
    })

    if (hasError) {
      throw new Error('naam_required')
    }

    orders.push(
      {
        order: orderNr,
        rows: orderRows
      }
    )
  })

  values.mainOriginalSupplier = values.mainSupplier
  values.onOriginalSupplier = values.onSupplier

  values.mainSupplier = thirdparties.find(tp => tp?.name?.toUpperCase() === values?.mainSupplier?.toUpperCase())?.code
  values.onSupplier = thirdparties.find(tp => tp?.name?.toUpperCase() === values?.onSupplier?.toUpperCase())?.code

  console.log('values', values)

  let jsonString = JSON.stringify({
    values,
    orders,
    saveToTest,
    saveToLive
  })

  // jsonString = JSON.stringify(order)
  // console.log('jsonstring3', jsonString)
  jsonString = jsonString.replace(/Onze referentie/g, 'onze_referentie')
  jsonString = jsonString.replace(/Ref\. kl\/Lev/g, 'ref_kl_lev')
  jsonString = jsonString.replace(/Order \(\.volgnr\)/g, 'order_volgnr')
  jsonString = jsonString.replace(/Artikelnummer/g, 'artikelnummer')
  jsonString = jsonString.replace(/Naam/g, 'naam')
  jsonString = jsonString.replace(/Oorsprong/g, 'oorsprong')
  jsonString = jsonString.replace(/Omschrijvingsblok/g, 'omschrijvingsblok')
  jsonString = jsonString.replace(/Munt/g, 'munt')
  jsonString = jsonString.replace(/Ordernr. oorsprong/g, 'ordernr_oorsprong')
  jsonString = jsonString.replace(/Gelev\.Eenh\. Voorraad/g, 'gelev_eenh_voorraad')
  jsonString = jsonString.replace(/Breedte/g, 'breedte')
  jsonString = jsonString.replace(/Dikte/g, 'dikte')
  jsonString = jsonString.replace(/Lengte/g, 'lengte')
  jsonString = jsonString.replace(/Aant. per verpak/g, 'aant_per_verpak')
  jsonString = jsonString.replace(/Lev.datum/g, 'lev_datum')
  jsonString = jsonString.replace(/Netto lijntotaal/g, 'netto_lijntotaal')
  jsonString = jsonString.replace(/Leveringsvoorw/g, 'leveringsvoorw')
  jsonString = jsonString.replace(/Vessel en voyage number/g, 'vessel_en_voyage_number')
  jsonString = jsonString.replace(/Marks numbers/g, 'marks_number')
  jsonString = jsonString.replace(/Volume/g, 'volume')
  jsonString = jsonString.replace(/Gross weight unit/g, 'gross_weight_unit')
  jsonString = jsonString.replace(/Gross Weight/g, 'gross_weight')
  jsonString = jsonString.replace(/Net weight unit/g, 'net_weight_unit')
  jsonString = jsonString.replace(/Net weight/g, 'net_weight')
  // jsonString = jsonString.replace(/Invoice/g, 'invoice')
  jsonString = jsonString.replace(/Reference qualifier/g, 'reference_qualifier')
  jsonString = jsonString.replace(/Botanical name/g, 'botanical_name')
  jsonString = jsonString.replace(/Certification/g, 'certification')
  jsonString = jsonString.replace(/ClaimConsignment/g, 'claim_consignment')
  jsonString = jsonString.replace(/Claim/g, 'claim')
  jsonString = jsonString.replace(/HS Code/g, 'hs_code')

  const csvurl = await api.saveJson(jsonString)
  console.log('csvurl api', csvurl)
  return csvurl
}

exports.writeExcel = writeExcel
