import Vue from 'vue'
import VueRouter from 'vue-router'

import Drop from '../views/Drop'
import Select from '../views/Select.vue'
import TabPage from '../views/TabPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'drop-page',
    component: Drop
  },
  {
    path: '/select',
    name: 'select',
    component: Select
  },
  {
    path: '/tabpage',
    name: 'tabpage',
    component: TabPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
