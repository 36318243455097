import Vue from 'vue'
import Vuex from 'vuex'

import { getUploadUrl } from '../services/api'
// eslint-disable-next-line no-unused-vars
import { getDataFromOrdas, convertExcelFromFile, getBotanics, getCommodities, getHSCodes, getThirdParties, getCountryOfOrigins, getLocations } from '../services/readExcel' // , getHSCodes, getBotanics
import { writeExcel } from '../services/writeExcel'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    uploadUrl: '',
    downloadUrl: '',
    csvUrl: '',
    trisSaved: false,
    uploadKey: '',
    headers: [],
    hscodes: [],
    botanics: [],
    thirdparties: [],
    countryOfOrigins: [],
    orders: [],
    packagecodes: ['CR – Crate', 'BE - Bundle', 'PC - Parcel', 'PG - Plate'],
    s3: {
      botanics: '',
      commodity: '',
      hscodes: '',
      thirdparties: '',
      thirdpartiesExtra: '',
      countryOfOrigins: '',
      locations: ''
    },
    ordersGeneralInfo: null,
    firstSlaChangeAfterCopy: [],
    updated: 0,
    error: null
  },
  mutations: {
    GET_UPLOAD_URL (state, uploadObj) {
      state.uploadUrl = uploadObj.uploadUrl
      state.downloadUrl = uploadObj.downloadUrl
      state.uploadKey = uploadObj.key

      state.s3.botanics = uploadObj.botanics
      state.s3.commodity = uploadObj.commodity
      state.s3.hscodes = uploadObj.hscodes
      state.s3.thirdparties = uploadObj.thirdparties
      state.s3.thirdpartiesExtra = uploadObj.thirdpartiesExtra
      state.s3.countryOfOrigins = uploadObj.countryoforigins
      state.s3.locations = uploadObj.locations
    },
    SET_CSVURL (state, csvUrl) {
      state.csvUrl = csvUrl
    },
    SET_TRIS_SAVED (state, saved) {
      state.trisSaved = saved
    },
    SET_ORDERS (state, orders) {
      state.orders = orders
    },
    SET_ORDERS_GENERAL_INFO (state, ordersGeneralInfo) {
      state.ordersGeneralInfo = ordersGeneralInfo
    },
    SET_HEADERS (state, headers) {
      state.headers = headers
    },
    SET_HSCODES (state, hscodes) {
      state.hscodes = hscodes
    },
    SET_BOTANICS (state, botanics) {
      state.botanics = botanics
    },
    SET_THIRDPARTIES (state, thirdparties) {
      state.thirdparties = thirdparties
    },
    SET_COUNTRYOFORIGINS (state, countryOfOrigins) {
      state.countryOfOrigins = countryOfOrigins
    },
    SET_LOCATIONS (state, locations) {
      state.locations = locations
    },
    SET_ERROR (state, error) {
      state.error = error
    },
    UPDATE_ORDER_ROW (state, changeObject) {
      const orders = { ...state.orders }

      const prop = this.state.headers[changeObject.colIndex]
      console.log('prop', prop)

      if (!changeObject.noUpdate) {
        orders[changeObject.order][changeObject.rowIndex][prop] = changeObject.value
        orders[changeObject.order][changeObject.rowIndex][orders[changeObject.order][changeObject.rowIndex].length - 1] = changeObject.autoCopyContent

        orders[changeObject.order].forEach((row) => {
          if (changeObject.autoCopyContent) {
            row[prop] = changeObject.value
          }
          row[row.length - 1] = changeObject.autoCopyContent
        })
      } else {
        orders[changeObject.order].forEach((row, index) => {
          const updateColIndexes = ['Package code', 'Gross Weight', 'Gross weight unit', 'Net weight', 'Net weight unit', 'Invoice', 'Botanical name', 'HS Code']
          // const updateColIndexes = ['Package code', 'Gross Weight', 'Gross weight unit', 'Net weight', 'Net weight unit', 'Botanical name', 'HS Code']

          row[row.length - 1] = changeObject.autoCopyContent
          if (index > 0) {
            updateColIndexes.forEach((index) => {
              row[index] = orders[changeObject.order][0][index]
            })
          }
        })
      }

      console.log('changeObject', changeObject)

      Vue.set(state, 'orders', orders)
    },
    COPY_ORDER_GENERAL_INFO (state, copyObject) {
      console.log('copyobject', copyObject)
      state.ordersGeneralInfo[copyObject] = copyObject.values
      for (const prop in state.ordersGeneralInfo) {
        state.firstSlaChangeAfterCopy[prop] = true
        if (prop !== copyObject.order) {
          console.log('prop', prop, copyObject)
          state.ordersGeneralInfo[prop] = copyObject.values
        }
      }
      state.updated++
    },
    ADD_BOTANIC (state, botanic) {
      state.botanics.push(botanic)
    },
    ADD_HSCODE (state, hscode) {
      state.hscodes.push(hscode)
    }
  },
  actions: {
    async getUploadUrl ({ commit }) {
      const uploadObj = await getUploadUrl()

      commit('GET_UPLOAD_URL', uploadObj)

      const botanics = await getBotanics(this.state.s3.botanics)
      commit('SET_BOTANICS', botanics)

      const hscodes = await getHSCodes(this.state.s3.hscodes)
      commit('SET_HSCODES', hscodes)

      await getCommodities(this.state.s3.commodity)

      const thirdpartiesExtra = await getThirdParties(this.state.s3.thirdpartiesExtra)
      const thirdparties = await getThirdParties(this.state.s3.thirdparties)
      console.log('store thirdparties', thirdparties, thirdpartiesExtra)
      thirdparties.push(...thirdpartiesExtra)
      commit('SET_THIRDPARTIES', thirdparties)

      console.log(' this.state.s3', this.state.s3)
      const countryOfOrigins = (await getCountryOfOrigins(this.state.s3.countryOfOrigins)).map(c => c.code)
      commit('SET_COUNTRYOFORIGINS', countryOfOrigins)

      const locations = (await getLocations(this.state.s3.locations)).map(l => l.location)
      commit('SET_LOCATIONS', locations)
    },
    async fileUploaded ({ commit }) {
      const { headers, orders, ordersGeneralInfo } = await convertExcelFromFile(this.state.downloadUrl)
      commit('SET_HEADERS', headers)
      commit('SET_ORDERS_GENERAL_INFO', ordersGeneralInfo)
      commit('SET_ORDERS', orders)
    },
    async getOrdasData ({ commit }, registrationNumbers) {
      console.log('store getOrdasData', registrationNumbers)
      // const { headers, orders, ordersGeneralInfo } =
      const { headers, orders, ordersGeneralInfo } = await getDataFromOrdas(registrationNumbers)
      commit('SET_HEADERS', headers)
      commit('SET_ORDERS_GENERAL_INFO', ordersGeneralInfo)
      commit('SET_ORDERS', orders)
    },
    copyOrderGeneralInfo ({ commit }, copyObject) {
      commit('COPY_ORDER_GENERAL_INFO', copyObject)
    },
    updateColumnInOrderRow ({ commit }, changeObject) {
      console.log('change', changeObject)
      commit('UPDATE_ORDER_ROW', changeObject)
    },
    async saveFile ({ commit, state }, order) {
      try {
        console.log('saveFile', order)
        const csvurl = await writeExcel(order, false, false, state.thirdparties)
        commit('SET_CSVURL', csvurl)
      } catch (error) {
        console.log('WRITE ERROR', error)
        commit('SET_ERROR', 'Naam niet ingevuld')
        setTimeout(() => {
          commit('SET_ERROR', null)
        }, 250)
      }
    },
    async saveToTestTris ({ commit, state }, order) {
      try {
        await writeExcel(order, true, false, state.thirdparties)
        commit('SET_TRIS_SAVED', true)
        setTimeout(() => {
          commit('SET_TRIS_SAVED', false)
        }, 250)
      } catch (error) {
        console.log('WRITE ERROR', error)
        commit('SET_ERROR', 'Naam niet ingevuld')
        setTimeout(() => {
          commit('SET_ERROR', null)
        }, 250)
      }
    },
    async saveToTestLive ({ commit, state }, order) {
      try {
        await writeExcel(order, false, true, state.thirdparties)
        commit('SET_TRIS_SAVED', true)
        setTimeout(() => {
          commit('SET_TRIS_SAVED', false)
        }, 250)
      } catch (error) {
        console.log('WRITE ERROR', error)
        commit('SET_ERROR', 'Naam niet ingevuld')
        setTimeout(() => {
          commit('SET_ERROR', null)
        }, 250)
      }
    },
    addBotanic ({ commit }, botanic) {
      commit('ADD_BOTANIC', botanic)
    },
    addHSCode ({ commit }, hscode) {
      commit('ADD_HSCODE', hscode)
    }
  },
  getters: {
    ordersArray: (state) => {
      return Object.keys(state.orders)
    },
    tod: (state) => (order) => {
      console.log('getter tod', state.orders[order])
      return state.orders[order].Leveringsvoorw
    }
  }
})
